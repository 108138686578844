define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faBars = {
    prefix: 'fal',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 80c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 96 0 88.8 0 80zM0 240c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zM448 400c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H432c8.8 0 16 7.2 16 16z"]
  };
  var faUsers = {
    prefix: 'fal',
    iconName: 'users',
    icon: [640, 512, [], "f0c0", "M96 80a48 48 0 1 1 96 0A48 48 0 1 1 96 80zm128 0A80 80 0 1 0 64 80a80 80 0 1 0 160 0zm96 80a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm0 160a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 64H378.7c54.2 0 98.4 42.5 101.2 96H160.1c2.8-53.5 47-96 101.2-96zm0-32C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352H261.3zM512 32a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm0 128A80 80 0 1 0 512 0a80 80 0 1 0 0 160zm16 64c44.2 0 80 35.8 80 80c0 8.8 7.2 16 16 16s16-7.2 16-16c0-61.9-50.1-112-112-112H444c2.6 10.2 4 21 4 32h80zm-336 0c0-11 1.4-21.8 4-32H112C50.1 192 0 242.1 0 304c0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.2 35.8-80 80-80h80z"]
  };
  var faUser = {
    prefix: 'fal',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faMoneyBills = {
    prefix: 'fal',
    iconName: 'money-bills',
    icon: [640, 512, [], "e1f3", "M576 64c17.7 0 32 14.3 32 32v32c-35.3 0-64-28.7-64-64h32zM224 64H512c0 53 43 96 96 96v96c-53 0-96 43-96 96H224c0-53-43-96-96-96V160c53 0 96-43 96-96zM128 288c35.3 0 64 28.7 64 64H160c-17.7 0-32-14.3-32-32V288zm416 64c0-35.3 28.7-64 64-64v32c0 17.7-14.3 32-32 32H544zM192 64c0 35.3-28.7 64-64 64V96c0-17.7 14.3-32 32-32h32zM160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM368 272a64 64 0 1 1 0-128 64 64 0 1 1 0 128zm-96-64a96 96 0 1 0 192 0 96 96 0 1 0 -192 0zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V368c0 61.9 50.1 112 112 112H528c8.8 0 16-7.2 16-16s-7.2-16-16-16H112c-44.2 0-80-35.8-80-80V112z"]
  };
  var faArrowsLeftRight = {
    prefix: 'fal',
    iconName: 'arrows-left-right',
    icon: [512, 512, ["arrows-h"], "f07e", "M503.3 271.3c6.1-6.1 6.3-15.9 .4-22.2l-96-104c-6-6.5-16.1-6.9-22.6-.9s-6.9 16.1-.9 22.6L455.5 244 50.6 244l68.7-68.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6l96 96c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L50.6 276l402.7 0-68.7 68.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96z"]
  };
  var faBarsProgress = {
    prefix: 'fal',
    iconName: 'bars-progress',
    icon: [512, 512, ["tasks-alt"], "f828", "M464 96c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H320V96H464zM48 96H288v96H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm0-32C21.5 64 0 85.5 0 112v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zM464 320c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H192V320H464zM48 320H160v96H48c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm0-32c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48H464c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48H48z"]
  };
  var faHexagon = {
    prefix: 'fal',
    iconName: 'hexagon',
    icon: [512, 512, [11043], "f312", "M16 224c-11.4 19.8-11.4 44.2 0 64l92.3 159.8c11.4 19.8 32.6 32 55.4 32l184.6 0c22.9 0 44-12.2 55.4-32L496 288c11.4-19.8 11.4-44.2 0-64L403.7 64.2c-11.4-19.8-32.6-32-55.4-32l-184.6 0c-22.9 0-44 12.2-55.4 32L16 224zm27.7 48c-5.7-9.9-5.7-22.1 0-32L136 80.2c5.7-9.9 16.3-16 27.7-16l184.6 0c11.4 0 22 6.1 27.7 16L468.3 240c5.7 9.9 5.7 22.1 0 32L376 431.8c-5.7 9.9-16.3 16-27.7 16l-184.6 0c-11.4 0-22-6.1-27.7-16L43.7 272z"]
  };
  var faLoader = {
    prefix: 'fal',
    iconName: 'loader',
    icon: [512, 512, [], "e1d4", "M272 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V16c0-8.8 7.2-16 16-16s16 7.2 16 16zm0 384v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V400c0-8.8 7.2-16 16-16s16 7.2 16 16zM0 256c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zm400-16h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16s7.2-16 16-16zM437 75c6.2 6.2 6.2 16.4 0 22.6l-67.9 67.9c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L414.4 75c6.2-6.2 16.4-6.2 22.6 0zM165.5 369.1L97.6 437c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l67.9-67.9c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM75 75c6.2-6.2 16.4-6.2 22.6 0l67.9 67.9c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L75 97.6c-6.2-6.2-6.2-16.4 0-22.6zM369.1 346.5L437 414.4c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-67.9-67.9c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0z"]
  };
  var faHouse = {
    prefix: 'fal',
    iconName: 'house',
    icon: [576, 512, [127968, 63498, 63500, "home", "home-alt", "home-lg-alt"], "f015", "M298.6 4c-6-5.3-15.1-5.3-21.2 0L5.4 244c-6.6 5.8-7.3 16-1.4 22.6s16 7.3 22.6 1.4L64 235V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V235l37.4 33c6.6 5.8 16.7 5.2 22.6-1.4s5.2-16.7-1.4-22.6L298.6 4zM96 432V206.7L288 37.3 480 206.7V432c0 26.5-21.5 48-48 48H368V320c0-17.7-14.3-32-32-32H240c-17.7 0-32 14.3-32 32V480H144c-26.5 0-48-21.5-48-48zm144 48V320h96V480H240z"]
  };
  var faHome = faHouse;
  var faGameBoardSimple = {
    prefix: 'fal',
    iconName: 'game-board-simple',
    icon: [448, 512, ["game-board-alt"], "f868", "M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM96 240H208V128H96V240zM240 120V240H360c13.3 0 24 10.7 24 24V392c0 13.3-10.7 24-24 24H232c-13.3 0-24-10.7-24-24V272H88c-13.3 0-24-10.7-24-24V120c0-13.3 10.7-24 24-24H216c13.3 0 24 10.7 24 24zm0 264H352V272H240V384z"]
  };
  var faTimer = {
    prefix: 'fal',
    iconName: 'timer',
    icon: [512, 512, [], "e29e", "M256 0c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V32.6C388.2 40.8 480 137.7 480 256c0 123.7-100.3 224-224 224S32 379.7 32 256c0-61.9 25.1-117.8 65.6-158.4c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0C28.7 121.3 0 185.3 0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0zM171.3 148.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l96 96c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-96-96z"]
  };
  var faPlus = {
    prefix: 'fal',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M240 64c0-8.8-7.2-16-16-16s-16 7.2-16 16V240H32c-8.8 0-16 7.2-16 16s7.2 16 16 16H208V448c0 8.8 7.2 16 16 16s16-7.2 16-16V272H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H240V64z"]
  };
  var faCirclePlus = {
    prefix: 'fal',
    iconName: 'circle-plus',
    icon: [512, 512, ["plus-circle"], "f055", "M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM240 352c0 8.8 7.2 16 16 16s16-7.2 16-16V272h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V160c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H160c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v80z"]
  };

  exports.faArrowsLeftRight = faArrowsLeftRight;
  exports.faBars = faBars;
  exports.faBarsProgress = faBarsProgress;
  exports.faCirclePlus = faCirclePlus;
  exports.faGameBoardSimple = faGameBoardSimple;
  exports.faHexagon = faHexagon;
  exports.faHome = faHome;
  exports.faLoader = faLoader;
  exports.faMoneyBills = faMoneyBills;
  exports.faPlus = faPlus;
  exports.faTimer = faTimer;
  exports.faUser = faUser;
  exports.faUsers = faUsers;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
